/* Sidebar and content */
.my-btn-sidebar {
    color: white;
}

.my-btn-sidebar:hover,
.my-btn-sidebar:focus,
.my-btn-sidebar.active {
    background-color: #2A7E8C;
}

.my-icon-arrow {
    transition: all 0.3s ease;
}

.my-btn-sidebar[aria-expanded="true"] .my-icon-arrow {  /*Identifies collapse when expanded*/
    transform: rotate(90deg);
}

.my-offcanvas {
    background-color: #00363F !important;
    color: white !important;
}

.my-offcanvas .btn-close {
    background-color: rgb(184, 184, 184);
}

.my-support-link {
    color: #00B8D9;
}

.my-group-header {
    color: rgb(181, 181, 181);
    cursor: default;
}
.my-group-header:hover {
    color: rgb(181, 181, 181) !important;
}

.my-sidebar-li {
    border-bottom: 1px solid rgb(76, 76, 76);
}

.my-content {
    transition: all 0.3s ease;
}

@media (min-width: 768px){
    .my-sidebar {                               /*Wrapper neccesary to animations (translate and transitions for the offcanvas block)*/
        width: 20%;
        padding: 1rem;
        overflow-y: auto !important;            /*max-height and overflow auto are neccesary to scroll*/
        max-height: calc(100vh - 72px);         /*viewport - header md*/
        transition: all 0.3s ease;              /*IMPORTANT, must use a wrapper for the offcanvas block to use animations*/
        position: fixed;
        left: 0;
        background-color: #00363F !important;
    }
    
    .my-offcanvas-body {
        min-height: calc(100vh - 72px - 2rem);  /*To always have vh, generates scroll when more content appears*/
        transition: all 0.3s ease;
    }

    .my-content {
        width: calc(100% - 20%);
        margin-left: 20%;
    }

    main.active .my-sidebar {
        transform: translatex(-100%);           /*To hide the element when ACTIVE (it is fixed so can move it around)*/
    }

    main.active .my-content{
        margin-left: 0 !important;              /*important! overrides JS class when toogle*/
        width: 100% !important;                 /*important! overrides JS class when toogle*/
    }
}

/* Loader */
.loader {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid rgb(196, 196, 196);
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border-bottom: 4px solid #d2deff;
    border-left: 4px solid transparent;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}