.my-btn-sidebar {
  color: #fff;
}

.my-btn-sidebar:hover, .my-btn-sidebar:focus, .my-btn-sidebar.active {
  background-color: #2a7e8c;
}

.my-icon-arrow {
  transition: all .3s;
}

.my-btn-sidebar[aria-expanded="true"] .my-icon-arrow {
  transform: rotate(90deg);
}

.my-offcanvas {
  color: #fff !important;
  background-color: #00363f !important;
}

.my-offcanvas .btn-close {
  background-color: #b8b8b8;
}

.my-support-link {
  color: #00b8d9;
}

.my-group-header {
  color: #b5b5b5;
  cursor: default;
}

.my-group-header:hover {
  color: #b5b5b5 !important;
}

.my-sidebar-li {
  border-bottom: 1px solid #4c4c4c;
}

.my-content {
  transition: all .3s;
}

@media (min-width: 768px) {
  .my-sidebar {
    width: 20%;
    max-height: calc(100vh - 72px);
    padding: 1rem;
    transition: all .3s;
    position: fixed;
    left: 0;
    background-color: #00363f !important;
    overflow-y: auto !important;
  }

  .my-offcanvas-body {
    min-height: calc(100vh - 72px - 2rem);
    transition: all .3s;
  }

  .my-content {
    width: 80%;
    margin-left: 20%;
  }

  main.active .my-sidebar {
    transform: translateX(-100%);
  }

  main.active .my-content {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.loader {
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  border-top: 4px solid #c4c4c4;
  border-right: 4px solid #0000;
  border-radius: 50%;
  animation: 1s linear infinite rotation;
  display: inline-block;
}

.loader:after {
  content: "";
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-bottom: 4px solid #d2deff;
  border-left: 4px solid #0000;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}



/*# sourceMappingURL=index.bd506f6d.css.map */
